.routerBar {
    position: fixed;
    top: 0;
}

.BottomNavigationAction-root.active .MuiBottomNavigationAction-label {
    color: blue;
}

.BottomNavigationAction-root.active .MuiSvgIcon-root {
    color: blue;
}
