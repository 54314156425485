form {
    background: inherit
}

.saveNewBuyerRequestButton {
    background-color: #3178C6;
    color: #FFFFFF;
    font-size: 14px;
    padding: 4px 8px 7px 8px;
    font-family: 'ClearSans', sans-serif;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    font-weight: 500;
    margin: 4px;
}

.reportDuplicateButton {
    background-color: #3178C6;
    color: #FFFFFF;
    font-size: 14px;
    padding: 4px 8px 7px 8px;
    font-family: 'ClearSans', sans-serif;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    font-weight: 500;
    margin: 4px;
    width:20%;
}



.dropzone {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-color: #eeeeee;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
}