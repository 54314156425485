body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  border-bottom: 1px solid white;
  color: #3d3d3d;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px;
  text-align: center;
}

form.sellerDuplicateForm {
  max-width: fit-content ! important;
  padding: 0px;
}

form {
  background: white;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 500px;
  padding: 30px 50px;
}

input {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
}

label {
  color: #3d3d3d;
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.error {
  color: red;
  font-family: sans-serif;
  font-size: 12px;
  height: 30px;
}

.submitButton {
  background-color: #6976d9;
  color: white;
  font-family: sans-serif;
  font-size: 14px;
  margin: 20px 0px;
}

h1 {
  border-bottom: 1px solid white;
  color: #3d3d3d;
  font-family: sans-serif;
  font-size: 20px;
  font-weight: 600;
  line-height: 24px;
  padding: 10px;
  text-align: center;
}

.categoryManagmentForm {
  background: white;
  border: 1px solid #dedede;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin: 0 auto;
  max-width: 500px;
  padding: 30px 50px;
}

.colorPickerDiv {
  display: inline-block;
}

.categoryManagmenInput {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
}

label {
  color: #3d3d3d;
  display: block;
  font-family: sans-serif;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 5px;
}

.error {
  color: red;
  font-family: sans-serif;
  font-size: 12px;
  height: 30px;
}

.submitButton {
  background-color: #6976d9;
  color: white;
  font-family: sans-serif;
  font-size: 14px;
  margin: 20px 0px;
}

.rcp {
  background-color: transparent !important;
}

.rcp-body {
  width: 90% !important;
  padding: 20px 20px !important;
}

.rcp-interactive {
  width: 90% !important;
}

.rcp-saturation, .rcp-hue, .rcp-interactive {
  width: 90% !important;
}

.rcp-field-input {
  color: black !important;
}

.rcp-hue-cursor, .rcp-saturation-cursor {
  height: 0px !important;
  width: 0px !important;
}

.blueButton {
  background-color: #3178C6;
  /*background-color: purple;*/
  color: white;
}

.greenButton {
  background-color: green;
  color: white;
}

.grayButton {
  background: gray;
  color: white;
}

.lightGrayButton {
  background: lightgray;
  color: white;
}

.redButton {
  background-color: red;
  color: white;
}

.whiteHeader {
  background: white;
}

.blink_me {
  animation: blinker 2s linear infinite;
  background: #3178C6;
  color: white;
}

@keyframes blinker {
  0% { opacity: .1; }
}
