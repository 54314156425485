button, .saveNewSellerRequestButton {
    background-color: #3178C6;
    color: #FFFFFF;
    font-size: 14px;
    padding: 4px 8px 7px 8px;
    font-family: 'ClearSans', sans-serif;
    border-radius: 3px;
    text-align: center;
    cursor: pointer;
    outline: none;
    border: none;
    font-weight: 500;
    margin: 4px;
}

.linkButton {
    position: absolute;
    left: 100px;
    width: 75px;
}

.sellerRequestButton {
    width: 150px;
}

.dmoTable tr:nth-child(odd) td{
    background-color: white;
    color: black;
}

.dmoTable tr:nth-child(even) td{
    background-color: white;
    color: black;
}

.DmoError {
    background-color: red;
    color: white;
    height: 30px;
    font-size: 30px
}

.DmoSuccess {
    background-color: springgreen;
    color: black;
    height: 30px;
    font-size: 30px
}

.outline {
    outline: lightgray thin solid;
    background: white;
}