.confirmationModal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    z-index: 1001;
}

.modalContent {
    text-align: center;
}

.btnConfirm, .btnCancel {
    margin: 10px;
    padding: 10px 20px;
    border: none;
    cursor: pointer;
}

.btnConfirm {
    background-color: blue;
    color: white;
}

.btnCancel {
    background-color: gray;
    color: white;
}
