.drag-drop {
    background: #fff;
    border: 1px solid var(--border-color);
    border-radius: 8px;
}

.document-uploader {
    border: 2px dashed #4282fe;
    background-color: #f4fbff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: 8px;
    cursor: pointer;
    width: 150%;

    &.active {
         border-color: #6dc24b;
     }

    .upload-info {
        display: flex;
        align-items: center;
        margin-bottom: 1rem;

    div {
        p {
            margin: 0;
            font-size: 16px;
        }

        p:first-child {
            font-weight: bold;
        }
    }
}

.file-list {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 10%;
}

.file-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid var(--border-color);
    border-radius: 8px;

    .file-info {
        display: flex;
        flex-direction: column;
        flex: 1;
}

.file-actions {
    cursor: pointer;

    &:hover {
        svg {
            color: #d44;
        }
    }
    }
}

.browse-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-color);
    border-radius: 8px;
    cursor: pointer;
    background-color: var(--primary-color);
    &:hover {
         background-color: transparent;
     }
}

.success-file {
    display: flex;
    align-items: center;
    color: #6dc24b;

    p {
        margin: 0;
        font-size: 14px;
        font-weight: bold;
    }
}

    input[type="file"] {
        display: none;
    }
}