.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.subtext {
  font-style: italic;
  font-size: smaller;
}

.sellerListTable {
  margin-left: auto;
  margin-right: auto;
}
thead {
  background: white;
}
th {
  position: sticky;
  top: 0;
}

.dropdown {
  position: relative;
}

.menu {
  position: absolute;
  background-color: whitesmoke;
  list-style-type: none;
  margin: 0;
  padding: 0;
  border: 1px solid grey;
  width: 500px;
  text-align: left;
  z-index: 1000;
}

.menu > li {
  margin: 0;
  padding: 10px;
}

.menu > li:hover {
  background-color: lightgray;
}

.blackNotification {
  color: black;
  font-size: 20px;
}

.thirtyOnePercentMenu {
  left: 31%;
}

.thirtyFourPercentMenu {
  left: 34%;
}

.fortyPercentMenu {
  left: 40%;
}

.fortyThreePercentMenu {
  left: 43%;
}

.fortySixPercentMenu {
  left: 46%;
}

.fiftyOnePercentMenu {
  left: 52%;
}

.sellerApplyButton {
  background-color: #3178C6;
  color: #FFFFFF;
  font-size: 14px;
  padding: 4px 8px 7px 8px;
  font-family: 'ClearSans', sans-serif;
  border-radius: 3px;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  font-weight: 500;
  margin: 4px;
}

.userError {
  color: red;
  font-size: 20px;
}

.userListTable {
  margin-left: auto;
  margin-right: auto;
}
